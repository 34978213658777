<template>
	<div>
		<div class="house_item" @click="toDetail"
			:style="{'backgroundColor':houseItem2.is_background==2?'rgba(255, 216, 0, 0.29)':'#ffffff',}">
			<div class="collect" v-if="houseItem2.is_hot==2">
				<img src="../assets/icons/important.png">
			</div>
			<div class="house_img">
				<img :src="houseItem2.thumb[0]">
			</div>
			<div class="house_title">
				{{houseItem2.title}}
			</div>
			<div class="house_price">
				<div class="house_price_left">
					<div v-if="houseItem2.price!='0.00'">
						${{houseItem2.price}}
					</div>
				</div>
				<div class="house_price_right">
					{{houseItem2.create_time}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['houseItem2'],
		data() {
			return {

			}
		},
		methods: {
			toDetail() {
				this.$router.push({
					path: '/detail',
					query: {
						information_id: this.houseItem2.id,
					}
				});
				// const path = this.$router.resolve({
				// 	path: '/home/detail',
				// 	query: {
				// 		information_id: this.houseItem2.id,
				// 	}
				// })
				// window.open(path.href, '_blank')
			}
		}
	}
</script>

<style scoped="" lang="less">
	.collect {
		position: absolute;
		top: 36px;
		right: 36px;

		img {
			width: 36px;
			height: 36px;
		}
	}

	.house_item {
		position: relative;
		cursor: pointer;
		box-sizing: border-box;
		margin-right: 16px;
		margin-bottom: 20px;
		width: 284px;
		height: 378px;
		padding: 22px 22px 16px;
		border: 1px solid #F0F0F0;
		background-color: #FFFFFF;

		.house_img {
			width: 240px;
			height: 240px;

			img {
				width: 240px;
				height: 240px;
				object-fit: cover;
			}
		}

		.house_title {
			line-height: 26px;
			color: #333333;
			font-size: 14px;
			margin: 16px 0 12px;
			word-break: break-all;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			/* 这里是超出几行省略 */
			overflow: hidden;
			text-align: left;

		}

		.house_price {
			display: flex;
			justify-content: space-between;

			.house_price_left {
				color: #F10200;
				font-weight: bold;
				font-size: 16px;
			}

			.house_price_right {
				color: #999999;
				font-size: 14px;
			}
		}
	}
</style>
