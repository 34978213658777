<template>
	<div class="search_result">
		<div class="top">
			<div class="title" @click="backClick">
				{{$t('other.homePage')}}
			</div>
			<div class="img">
				<img src="../../../assets/home_icons/front.png">
			</div>
			<div class="sub_title">{{$t('title.hotRecommand')}}</div>
		</div>
		<div class="all_house">
			<houseItem2 v-for="(item,index) in recommendList" :houseItem2="item"></houseItem2>
		</div>
		<div class="pagination">
			<el-pagination background layout="prev, pager, next,sizes" :total="total"
				@current-change="currentChangeClick" @size-change="sizeChangeClick" :current-page="currentPage"
				:page-size="pageSize" :background="true" popper-class="select_bottom" :page-sizes="pageSizes">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import houseItem2 from '@/components/houseItem2.vue'

	export default {
		components: {
			houseItem2
		},
		data() {
			return {
				recommendList: [],

				total: 8, //总条数
				currentPage: 1, //当前页数
				pageSize: 16, //每页的条数
				pageSizes: [16, 20, 30, 40, 50, 100],
			}
		},
		mounted() {
			this.getRecommand();
		},
		methods: {
			getRecommand() {
				this.$http.getRecommend({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					is_index: 0,
					type: 1,
					title: this.title,
				}).then(res => {
					if (res.code == 1) {
						this.total = res.data.total;
						this.recommendList = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
			},
			backClick() {
				this.$router.push('/home');
			},
			currentChangeClick(currentPage) {
				this.currentPage = currentPage;
				this.getRecommand();

			},
			sizeChangeClick(pageSize) {
				this.pageSize = pageSize;
				this.getRecommand();
			},
		}
	}
</script>

<style scoped="" lang="less">
	.search_result {
		width: 1200px;
		margin: 0 auto;
		margin-top: 24px;
	}

	.top {
		display: flex;
		align-items: center;
		font-size: 14px;

		.title {
			color: #999999;
		}

		.title:hover {
			cursor: pointer;
		}

		.img {
			display: flex;
			align-items: center;
			margin: 0 8px;

			img {
				width: 8px;
				height: 8px;
			}
		}

		.sub_title {
			color: #333333;
		}
	}

	.all_house {
		margin-top: 24px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.pagination {
		margin-top: 24px;
		display: flex;
		justify-content: flex-end;
	}
</style>
